

<template>
  <main class="home">
    <headerElem></headerElem>
    <div class="text-intro">
      Testez vos<br />connaissances en<br />participant à notre<br />quizz en 5
      questions
    </div>
    <div class="start">
      <router-link tag="button" to="/form">commencer</router-link>
    </div>
  </main>
</template>
<script>
import HeaderElem from "@/components/Header";
export default {
  name: "lz-home",
  components: {
    HeaderElem,
  },
};
</script>
