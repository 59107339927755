<template>
  <div id="app" v-bind:class="this.$router.currentRoute.name">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <FooterElem></FooterElem>
  </div>
</template>

<script>
import HeaderElem from "@/components/Header";
import FooterElem from "@/components/Footer";

export default {
  name: "App",
  data: function() {
    return {
      menu: false,
    };
  },
  mounted() {
    const appHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
  },
  components: {
    HeaderElem,
    FooterElem,
  },
};
</script>
<style lang="scss" scoped>
.lz-home {

  min-height: 850px;
  @media screen and (max-width: 414px) {
    min-height: 108vw;
  }

  @media screen and (max-width: 360px) {
    min-height: 100vw;
  }

  @media screen and (max-width: 330px) {
    min-height: 100vw;
  }
}
.lz-done {

  min-height: 1050px;

  @media screen and (max-width: 414px) {
    min-height: 120vw;
  }

  @media screen and (max-width: 360px) {
    min-height: 200vw;
  }

  @media screen and (max-width: 330px) {
    min-height: 200vw;
  }
}

.lz-form {

  min-height: 1050px;
  @media screen and (max-width: 414px) {
    min-height: 200vw;
  }

  @media screen and (max-width: 375px) {
    min-height: 205vw;
  }

  @media screen and (max-width: 360px) {
    min-height: 230vw;
  }

  @media screen and (max-width: 330px) {
    min-height: 245vw;
  }
}

.lz-quizz{
 // background-image: url(./assets/images/background7.jpg);
  min-height: 1000px;
  @media screen and (max-width: 414px) {
    min-height: 170vw;
  }

  @media screen and (max-width: 375px) {
    min-height: 80vw;
  }

  @media screen and (max-width: 360px) {
    min-height: 80vw;
  }

  @media screen and (max-width: 330px) {
    min-height: 20vw;
  }
}
.lz-wait, .lz-finish{
   // background-image: url(./assets/images/background7.jpg);
    color: white;
}

.lz-mentions {
    min-height: 1200px;
  @media screen and (max-width: 414px) {
    min-height: 275vw;
  }

  @media screen and (max-width: 375px) {
    min-height: 295vw;
  }

  @media screen and (max-width: 360px) {
    min-height: 310vw;
  }

  @media screen and (max-width: 330px) {
    min-height: 315vw;
  }
}

</style>
