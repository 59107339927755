<template>
    <header>
        <Drawer @close="toggle" align="right" :closeable="true">
            <div v-if="open">
                <ul><li @click="toggle"><router-link to="/"><img src="../assets/images/logo.png"></router-link></li>
                    <li @click="toggle"><router-link to="/form">Participer</router-link></li>
                    <li @click="toggle"><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${game_id}/reglement.pdf`" target="_blank">Règlement du jeu</a></li>
                    <li @click="toggle"><router-link to="/mentions">Mentions légales</router-link></li>
                    <li @click="toggle"><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${game_id}/politique.pdf`" target="_blank">Politique de confidentialité</a></li>
                    <li @click="toggle"><router-link to="/contact">Contact</router-link></li>
                </ul>
            </div>
         </Drawer>
         <div>
             <router-link to="/"><img class="logo" src="../assets/images/logo.png"></router-link>
             <img class="menumob" @click="toggle" src="../assets/images/menu.png">
         </div>
    </header>
</template>
<script>
var consts = require('../config');
import Drawer from "vue-simple-drawer"

export default {
    name: 'HeaderElem',
    data() {
        return {
            game_id : consts.GAME_ID,
            open: false
        }
    },
    components: {
        Drawer
    },
    methods: {   
        toggle() {
            this.open = !this.open
        }
    }    
}
</script>